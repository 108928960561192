$(function() {
  $('.fancybox').fancybox();


  if (isMobile) {
    $('.top-menu .with-dropdown').on('click', '> a, > span', function(e) {
      var $this = $(this);
      var $thisParent = $this.closest('.with-dropdown');

      if (this.tagName === 'SPAN') {
        if ($thisParent.hasClass('mobile-hover')) {
          $thisParent.removeClass('mobile-hover');
        } else {
          $thisParent.addClass('mobile-hover');
        }
      }

      else if (this.tagName === 'A') {
        e.preventDefault();
        if ($thisParent.hasClass('mobile-hover')) {
          document.location.href = $this.attr('href');
        } else {
          $thisParent.addClass('mobile-hover');
        }
      }

      $('.top-menu li').not($thisParent).removeClass('mobile-hover');
    });
  }

  // var picsLimit = 12,
  //     feed;

  // var instafeedInit = function(userId, accessToken) {
  //   feed = new Instafeed({
  //     clientId: '9f5c4436b4774771a9ccd7db93cb73f2',
  //     get: 'user',
  //     userId: userId,
  //     // filter: function(image) { // Это должно быть только на страницах брендов
  //     //   return image.tags.indexOf('TAG_NAME') >= 0; // TAG_NAME надо заменить на тег-имя бренда
  //     // },
  //     accessToken: accessToken,
  //     limit: picsLimit,
  //     resolution: 'low_resolution',
  //     template: '<a href="{{link}}" target="_blank"><img src="{{image}}" alt="" /></a>',
  //     after: function() {
  //       var newPage = $('<div class="instapage"></div>'),
  //         newPics = $('#instafeed a:gt(' + (picsLimit - 1) + ')').detach();
  //       newPage.append( newPics );
  //       if ( newPage.find('a').length ) {
  //         $('.instawidget').append( newPage );
  //       }
  //     }
  //   });
  //   feed.run();

  //   setTimeout(function() {
  //     feed.next();
  //   }, 1000);

  //   $('.inst-next-page').click(function(e) {
  //     e.preventDefault();
  //     feed.next();
  //     var activePage = $('.instapage.active'),
  //       pageWidth = $('.small-wrapper').width(),
  //       widget = $('.instawidget'),
  //       currentPosition = parseInt( widget.css('left'), 10 );
  //     if ( $('.instapage.active').next().length ) {
  //       $('.instapage.active').removeClass('active').next().addClass('active');
  //       widget.css( 'left', currentPosition - pageWidth );
  //     }
  //     if ( $('.instapage.active').prev().length ) {
  //       $('.inst-prev-page').removeClass('disabled');
  //     }
  //     if ( feed.hasNext() || $('.instapage.active').next().length ) {
  //       $('.inst-next-page').removeClass('disabled');
  //     } else {
  //       $('.inst-next-page').addClass('disabled');
  //     }
  //   });

  //   $('.inst-prev-page').click(function(e) {
  //     e.preventDefault();
  //     var activePage = $('.instapage.active'),
  //       pageWidth = $('.small-wrapper').width(),
  //       widget = $('.instawidget'),
  //       currentPosition = parseInt( widget.css('left'), 10 );
  //     if ( $('.instapage.active').prev().length ) {
  //       $('.instapage.active').removeClass('active').prev().addClass('active');
  //       widget.css( 'left', currentPosition + pageWidth );
  //     }
  //     if ( !$('.instapage.active').prev().length ) {
  //       $('.inst-prev-page').addClass('disabled');
  //     }
  //     if ( $('.instapage.active').next().length ) {
  //       $('.inst-next-page').removeClass('disabled');
  //     }
  //   });
  // }

  $(document).on('click', '.js-video', function(ev) {
    ev.preventDefault();

    var $popup = $('#video-popup')
    var videoID = $(this).data('video-id');
    var video = [
      '<iframe width="853" height="480"',
      'src="https://www.youtube-nocookie.com/embed/' + videoID + '?autoplay=1&rel=0"',
      'frameborder="0" allowfullscreen></iframe>'
    ].join('');

    $popup.append(video);

    $.overlayLoader(true, {
      node: $popup,
      onHide: function() {
        $popup.find('iframe').remove();
      }
    })
  });

  $('#video-popup').on('click', '.close', function(ev) {
    ev.preventDefault();

    $.overlayLoader(false, { node: 'video-popup' });
  });

  $('.js-overlay').click(function() {
    $.overlayLoader(true, {
      node: $($(this).attr('href'))
    });
  });

  $('.js-get-video-image').each(function() {
    var $video = $(this);
    var requestUrl = [
      'https://www.googleapis.com/youtube/v3/videos',
      '?part=snippet',
      '&id=' + $video.data('video-id'),
      '&maxResults=1',
      '&key=AIzaSyBBuLNXoheL7nfLELH1JxK_y7HOWq4aPCk'
    ].join('');

    $.ajax({
      url: requestUrl,
      cache : false,
      dataType: 'jsonp'
    }).done(function(data) {
      var imageUrl = data.items[0].snippet.thumbnails.high.url;
      $video.find('img').attr('src', imageUrl);
    });
  });

  // window.instafeedInit = instafeedInit;

  $('.js-instagram-slider').each(function() {
    var $slider = $(this);

    $slider.slick({
      slidesToShow: 6,
      slidesToScroll: 6,
      infinite: false,
      dots: false,
      arrows: true,
      prevArrow: '.inst-prev-page',
      nextArrow: '.inst-next-page'
    });
  });
});
